.services{
    width: 90%;
    margin: auto;
}

.services .designs{
    margin: 3vh 0;
    padding: 4vh 5%;
    border-radius: 5px;
    box-shadow: 3px 4px 10px 2px rgba(2, 0, 0, 0.7);
    background-color: whitesmoke;
}

.services h2{
    text-align: center;
}

.services .mandv div p{
    padding: 2vh;
    border-radius: 5px;
    text-align: justify;
}

.services .mandv div p li{
    list-style:square;
}
.services .mandv div img{
    width: 40%;
    height: 30vh;
}
.services .justify {
    margin: 3vh 0;
    padding: 4vh 5%;
    border-radius: 5px;
    box-shadow: 3px 4px 10px 2px rgba(2, 0, 0, 0.7);
    background-color: whitesmoke;
}
.services .corea{
    margin: 3vh 0;
    padding: 4vh 5%;
    border-radius: 5px;
    box-shadow: 3px 4px 10px 2px rgba(2, 0, 0, 0.7);
   
}
@media screen and (max-width: 500px) {
    .services .mandv div img{
        width: 100%;
        height: 25vh;
    }
}
  