.numbers{
    display: flex;
    width: 100%;
    height: max-content;
    justify-content: space-between;
    align-items: center;
    margin-top: 7vh;
    flex-wrap: wrap;
}
.numbers .numb{
    border-radius: 5px;
    min-width: 20%;
    background-color: rgb(5, 131, 177);
    border: 1px solid black;
    height: 80%;
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.numbers .numb p{
  font-weight: 550;
}

.numbers .numb:hover{
    background-color: white;
    cursor: pointer; 
    color: black;
}

@media (max-width: 800px) {
    .numbers{
        width: 98%;
        margin: auto;
    }
    .numbers .numb{
       
        min-width: 46%;
        margin-top: 5vh;
    }
}