.uppertopbar{
  width: 100%;
  height: max-content;
  margin: 0;
  z-index: 100;
  top: 0px; 
}
.uppertopbar .upper{
  height: 10vh;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  padding: 0 4%;
  
}

.uppertopbar .upper .name{
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
}
.uppertopbar .upper .logo{
 width: 18%;
 height: 100%;
 display: flex;
 align-items: center;
 
}
.uppertopbar .upper .logo img{
  height: 85%;
  width: 40%;
  
}
.uppertopbar .upper .donder-name2{
  padding: 0 5px;
  color: rgb(29, 29, 29); 
  font-size: 1.6rem;
  font-weight: 700;
}

.uppertopbar .upper .donder-name{
  padding: 0 5px;  
  font-size: 1.6rem;
  font-weight: 700;
}


.uppertopbar .upper h1{
  text-align: center;
  color: rgb(1, 83, 113);
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.uppertopbar .upper .button{
  width: 18%; 
  display: flex;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 3px 4px 10px 1px rgba(2, 0, 0, 0.5);
 
 
}
.uppertopbar .upper button{
  padding: 10px 5px;
  width: 20%;
  margin: 0;
  border: none;
  background:  yellow;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
}
.uppertopbar .upper input{
  padding: 10px 5px;
  width: 80%;
  margin: 0;
  border: none;
  background:  white;
 
  font-weight: bold;
  font-size: 0.8rem;
  
}

@media screen and (max-width: 960px) {
  .uppertopbar{
    display: none;
  }
}