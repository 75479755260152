.cellebration{
    display: flex;
    flex-direction: row ;
    flex-wrap: wrap;
    color: white;
    margin-bottom: 10vh;
    margin-top: 10vh;
    justify-content: space-between;
}
.cellebration>div{
    width: 30%;
   background-color: rgb(5, 131, 177);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    
    border: 2px solid black;
}

.cellebration>div h2{
  color: yellow;
}

.cellebration div li{
    list-style: none;
    margin-bottom: 5vh;
}
.cellebration .right img{
   height: 180px;
   width: 180px;
   margin-bottom: 2vh;
}
.cellebration .left span{
   
    padding: 1vh 1vh;
   
 }


@media screen and (max-width: 768px) {
    .cellebration div{
        width: 90%;
        margin: auto;
        border: none;
    }
    .cellebration .right{
       border: 2px solid black;
       padding: 1vh 1vh;

       
    }
    .cellebration .left{
        border: 2px solid black;
        padding: 1vh 1vh;
       
     }
     .cellebration .center{
   
        margin-top: 4vh;
        margin-bottom: 4vh;
     }
}



