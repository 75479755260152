.screen{
  background-color: white;
  height: 75vh; 
}
.screen .picandword {
    height: 70vh;
    width: 100%;
   
}

.screen .pic1 {
  background-image: url("../../../images/screen/screen1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}
.screen .pic2 {
  background-image: url("../../../images/screen/screen2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}
.screen .pic3 {
  background-image: url("../../../images/screen/screen3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}

.screen .pic4 {
  background-image: url("../../../images/screen/screen4.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}

.screen .pic5 {
  background-image: url("../../../images/screen/screen5.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}


.screen .container{

 width: 65%;
 height: 60%;
 margin: 10% 15%;
 text-align: center;

   
}

.screen .screen-but {
  margin-top: 4vh;
}

.screen .screen-but button{
  background-color: yellow;
  color: black;
  padding: 10px 18px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
.screen .screen-but button:hover{
color: rgb(4, 222, 222);
background-color: black;

padding: 12px 20px;
}


.screen span {
  font-size: 1.3rem;
  color: white;
  font-weight: 500;
  
}

.screen h1 {

 color: rgb(63, 177, 218);
 text-align: center; 
  
}


@media (max-width: 768px) {
  .screen{
    background-color: white;
    height: 70vh; 
  }
  .screen .picandword {
    height: 65vh;
    width: 100%;
   
}
  .screen .container{

    width: 80%;
    height: 70%;
    margin: 10% 15%;   
   }
}

@media (max-width: 576px) {
  .screen{
    background-color: white;
    height: 60vh; 
  }
  .screen .picandword {
    height: 55vh;
    width: 100%;
   
}
  .screen .container{

    width: 96%;
    height: 60%;
    margin: 20% 2%;
     
   }
  
  .picandword > div > h1 {
    font-size: calc(0.5rem + 2vw);
  }
  .screen .screen-but button{
    padding: 8px 15px;
    font-size: 1rem;
  }
}
