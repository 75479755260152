.back {
    min-height: 45vh;
    height: max-content;
    position: relative;
    overflow: hidden;    
  }
  .back img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
   
  }
  .back p{
    text-align: center;
  }
  .back::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(17, 40, 72, 0.629);
    z-index: -1;
     
  }
 
  .back .cont {
    color: #fff;
    padding: 50px 0;
  }
  .back h1 {
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    animation: show 5s ease-in-out  infinite; 
  }

  @keyframes show {
    0% {  
      transform: translateY(200%); 
      opacity: 0.4;
       
    }

    50% {
      transform: translateX(0%);
      transform: translateY(0%);
      color: #fff; 
      opacity: 1;
     
    }
   
    100% {
      transform: translateX(200%); 
      color: rgb(130, 202, 250);       
    }
  }

  @media screen and (max-width: 480px) {
    .back h1{
      font-size: 2em;
    }
  }