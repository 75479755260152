.topbar {

  background-color: rgb(63, 177, 218);
  width: 100%;
  height: 11vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky !important;
  top: 0px;
  z-index: 1001;
  
  
}

.topbar .container {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
  align-items: center;
  width: 100%;
  height: 10vh;
}

.topbar .phone{
  display: none;
}

.topbar .lower{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0;
  flex-grow: 1;
 
}


.topbar .lower li{
  height: 4vh;
  text-decoration: none;
  list-style-type: none;
  padding: 6px 10px;
  font-weight: 500;
  color: black;
  border-radius: 3px;
  cursor: pointer;
    background-color: yellow;
}

.topbar .lower li:active{
  background-color: black;
}

.topbar .lower li:hover{
  color: black;
  background-color: white;
  box-shadow: 3px 4px 10px 1px rgba(2, 0, 0, 0.5);
}

.topbar .togle>img{
  cursor: pointer;
  margin-right: 0vw; 
  height: 5vh !important ;
  width:6vh!important;
  border-radius: 3px;
  border: 2px solid rgb(75, 190, 228);;
}


@media screen and (max-width: 960px) {
  .topbar{
    height: 10vh;
  }
  .topbar .phone{
    display: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }
  .topbar .lower{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width:100%;
    top: 10vh;
    position: absolute;
    left: -100vw;
    opacity: 0;
    transition: all 0.5s ease;
 }
 
  .topbar .lower.active{
    background-color: whitesmoke;
    background-repeat: repeat;
    height: max-content;
    top: 10vh;
    margin-top: 0px;
    display: flex;
    align-items: center;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .topbar .lower li{
    text-decoration: none;
    list-style-type: none;
    background-color: transparent;
    text-transform: uppercase;
    padding: 1vh 0vh;
    background-color:rgb(63, 177, 218);
    width: 75%;
    border-radius: 3px;
    margin: 2vh 0;
    font-weight: 500;
    text-align: center;
    color: whitesmoke;
    cursor: pointer;
    box-shadow: 3px 4px 10px 1px rgba(2, 0, 0, 0.6);
  }
  .topbar h2{
    font-size: 1.2rem;
  }
  
  
  
}
