.helpyou{
    width: 100%;
    margin: 10vh 0;
    height: max-content;
}

.helpyou h2{
   text-align: center;
   padding: 1vh 0;
   font-size: calc(1.3rem + 1vh);
   
}

.helpyou .helpdiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.helpyou .helpdiv .ite {
    height: 100%;
    width: 30%;
    display: flex;
    margin-bottom: 5vh;
    box-shadow: 3px 4px 10px 2px rgba(2, 0, 0, 0.7);
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    background-color: whitesmoke;
}

.helpyou .helpdiv .ite span{
   font-size: calc(1rem + 0.4vw);
   font-weight: bold;
   margin-bottom: 2vh;
   color: black;
}

.helpyou .helpdiv .ite img{
    height: 50%;
    width: 20vh;
}

.helpyou .helpdiv .ite button{
   padding: 10px 16px;
   margin: 2vh 0;
   border: none;
   border-radius: 5px;
   background:  rgba(41, 131, 191, 0.9);
   font-weight: bold;
   font-size: 1rem;
   cursor: pointer;
}

.helpyou .helpdiv .ite button:hover{
    background-color: black;
    color: yellow;
}

.helpyou .helpdiv .ite p{
    text-align: center;  
    color: black;
}
 
@media screen and (max-width: 576px) {
    .helpyou .helpdiv .ite {
       
        width: 100%;
        
    }
}

@media screen and (max-width: 768px) {
    .helpyou .helpdiv .ite {
       
        min-width: 40%;
        flex-grow: 1;
        margin:2vh 1vh;
    }
   
   
}