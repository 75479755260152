.whyus{
    width: 100%;
    margin: 0;
    height: max-content;
    background-color: whitesmoke;
    padding-bottom: 5vh;
    border-radius: 5px;
    
}
.whyus .sub-why{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 96%;
    margin: auto;
    overflow: hidden;
    
    
}

.whyus .heading{
    display: flex;
    justify-content: center;
}

.whyus .choose{
   color: darkcyan;
   padding: 0 10px;
}

.whyus p{
    text-align: center;
    padding: 2vh 2vh;

}
.whyus .sub-why .box{
    width: 20%;
}

.whyus .why-img{
    width: 90px;
    height: 80px;
    padding: 2vh 2vh;
    background-color: rgb(5, 131, 177);
    
   
    cursor: pointer;
    border-radius: 10px;
    
}
.whyus .why-img:hover{
    background-image: none;
    
    background-color: yellow;
    box-shadow: 3px 4px 5px 2px rgba(0, 0, 2, 0.7);
    
}
.whyus .box{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.whyus .box span{
    font-weight: 600;
    font-size: 1.3rem;
    text-align: center;
    margin-top: 2vh;
}

@media screen and (max-width: 768px) {
    .whyus .sub-why .box{
        min-width: 40%;
        flex-grow: 1;
        margin: 1vh 1vh;
        padding-top: 1vh;
        border: 2px dotted black;
        border-radius: 10px;
       
        
    }
    .whyus .box span{
        
        margin-top: 2vh;
    
    }
}

@media screen and (max-width: 480px) {
    .whyus .sub-why .box{
        min-width: 80%;
        flex-grow: 1;
        border-radius: 5px;
        padding-top: 2vh;
        border: 1px dotted black;
       
    }
}