.contact{
display: flex;
flex-direction: column;
align-items: center;
padding: 5vh 0;
width: 100%;


}

.contact .container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin:2vh auto;
}

.contact .handles{
width: 80%;
margin: auto;
padding: 0;
}

.contact .handles .box{
  cursor: pointer;
  width: 20%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 2vh 2vh;
  margin: 1vh !important;
  background-color: white;
  border-radius: 4px;
  flex-grow: 1;
    
}
.contact .handles .box img{
  width: 8vh ;
  height: 8vh;
    
}

.contact form div {
  display: flex;
}
.contact textarea {
  border: 2px solid  #856741 ;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;

}
.contact textarea:active {
  
  background-color: #0ecccf;

}
.contact input {
  margin-bottom: 20px;
  margin-right: 10px;
  background-color: #0ecccf;
  border: none;
  padding: 10px;
  color: white;
}

.contact form {
  padding: 20px;
  width: 80%;
  border-radius: 1vh;
  background-color: white;
}

.contact .btn2{
  background-color: #0ecccf;
  border: none;
  padding: 8px 14px;
  font-size: 1.3rem;
  font-weight: 600;
  border-radius: 3px;
}
.contact .btn2:hover{
  background-color: yellow;
  cursor: pointer;
  padding: 9px 16px;
}

  
@media screen and (max-width: 768px) {
  .contact h4 {
    width: 100%;
    margin-bottom: 30px;
  }
  .contact form div {
    flex-direction: column;
  }
  .contact input {
    width: 100%;
  }
  .contact .handles{
    width: 90%;
    margin: auto;
    padding: 0;
    }
}

@media screen and (max-width: 576px) {
  .contact form {
    width: 95%;
    padding: 3vh 0;
    padding-left: 1vh;
      
  }
  .contact input {
    width: 90%;
  }
  .contact textarea {
    width: 93%;
  }
  
.contact .btn2{
  font-size: 1.2rem;
  
}
.contact .handles{
  width: 95%;
  }
.contact .handles .box img{
  width: 5vh ;
  height: 5vh;
    
}

}

