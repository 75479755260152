.gallerydisplay{
    width: 100%;
}

.gallerydisplay .container{
    width: 90%;
    margin: 5vh auto;
    display: flex;
    flex-wrap: wrap;
}
.gallerydisplay>h2{
text-align: center;
}
.gallerydisplay .container .large{
    width: 30%;
    flex-grow: 2;    
}
.gallerydisplay .container .small{
    width: 20%;
    flex-grow: 1; 
}
.gallerydisplay .container .boxx{
    height: auto;  
    cursor: pointer;
    margin: 1vh 1vh; 
}


.gallerydisplay .container .boxx img{
    width: 100%;
    height: 55vh;
    box-shadow:rgba(50, 50, 93, 0.9) 0px 2px
    5px -1px, rgba(0, 0, 0, 0.4) 0px 1px 3px -1px;
}

.gallerydisplay .container img:hover{
    transform: translateZ(5px) !important; 
}
.gallerydisplay .container .boxx{
    position: relative;
    transition: 0.5s;
  }
  
  .gallerydisplay .boxx:hover .overlay::after,
  .gallerydisplay .boxx:hover .overlay {
    opacity: 1;
  }
  .gallerydisplay .overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    transition: 0.5s;
    z-index: 2;
  }
  .gallerydisplay .overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 0, 0.60);
    opacity: 0;
    transition: 0.5s;
    z-index: -1;
  }
  

@media screen and (max-width: 768px) {
    .gallerydisplay .container .boxx img{
        
        height: 40vh;
    }
    .gallerydisplay .container{
        width: 96%;
    }
    .gallerydisplay .container .large{
        width: 30%;
        flex-grow: 2;
        
    }
    .gallerydisplay .container .small{
        width: 20%;
        flex-grow: 1;
        
    }
 }

 @media screen and (max-width: 576px) {
    .gallerydisplay .container{
        width: 98%;
    }
    .gallerydisplay .container .large{
        width: 50%;
        flex-grow: 1;
    }
    .gallerydisplay .container .small{
        width: 30%;
        flex-grow: 1.5;
    }
    .gallerydisplay .container .boxx img{
        height: 30vh;
    }
 }

 @media screen and (max-width: 480px) {
    
    .gallerydisplay .container .boxx img{
        height: 25vh;
    }
 }