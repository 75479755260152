.quotation {
margin: 0;
padding: 5vh 0;
}
.quotation h1{
    text-align: center;
    margin-top: 2vh;
}

.quotation .containerr {
padding: 20px 5%;

}
.quotation .containerr .content{
width: 100%;

}
.quotation .flex{
    
    display: flex;
    justify-content: space-between;
}

.quotation button{
    width: 100%;
    padding: 20px;
    margin: 10px 0;
    border-radius: 5px;
    border: none;
    cursor: pointer;
outline: none;
background-color: yellow;
}

.quotation textarea{
    width: 99.5%;
    margin: 10px 0;
  border-radius: 5px; 
  border: none;
outline: none;  
}
.quotation input {
    width: 45%;
    padding: 20px;
border: none;
outline: none;
margin: 10px 0;
border-radius: 5px;
}

.quotation .subject{
    width: 96%;
    padding: 20px 2%;
}