.projects{
  height: max-content;
  margin-bottom: 10vh;
  width: 94%;
  margin-left: 3%;
  
}

.projects .slide{
  background-color: white;
  width: 21.5vw !important;
  height: 55vh;
  padding: 1vh 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 1vw;
  
  box-shadow: 3px 4px 10px 2px rgba(2, 0, 0, 0.7);
 border-radius: 5px;
}

.projects img{
  width: 95%;
  height: 55%;
  border-radius: 6px;

 
}

.projects .onimg{
  margin-top: 1vh;

}

.projects .onimg h3{
 font-weight: 600;
 
}
.projects h2{
  text-align: center;
  margin-bottom: 4vh;
  color: black;
}

@media screen and (max-width: 768px) {
  .projects{
  
    width: 90%;
    margin-left: 5%;
    
  }
  
  .projects .slide{
      width: 40vw !important;
      margin: 1vw;
      
  }
 
}

@media screen and (max-width: 576px) {
  .projects{
  
    width: 85%;
    margin-left: 7.5%;
    
  }
  .projects .slide{
      width: 80vw !important;
      margin: 2vw;
  }
  
  .projects p {
      font-size: 12px;
    }
    .projects h4 {
      font-size: 13px;
    }
  
}