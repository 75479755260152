.homepage{
    width: 86%;
    margin-left: 7%;
    margin-bottom: 0;
   
}

@media screen and (max-width: 768px) {
    .homepage{
        width: 90%;
        margin-left: 5%;
       
    }
   
}

@media screen and (max-width: 576px) {
    .homepage{
        width: 96%;
        margin-left: 2%;
       
    }
   
}