.footer{
    width: 100%;
    height: max-content;
    border-bottom: 1px solid white;
    margin: 0px;
    padding: 0px;
    color: white;
    background-color: rgb(44, 42, 42);
}

.footer .footer-subdiv{
    display: flex;
    height: 100%;
    margin-bottom: 0%;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer .footer-subdiv div h3{
    text-decoration: underline  rgb(75, 190, 228) 3px;
    text-underline-offset: 10px;
    text-align: center;
    text-transform: uppercase;
}
.footer .footer-subdiv div p{
    text-align: center;
    cursor: pointer; 
}

.footer .footer-subdiv div p:hover{
   color: yellow;
}

.footer .copright{
    text-align: center;
    padding: 20px;
    color: white;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
  .footer .copright a{
   color: #00a1e4;}
@media screen and (max-width: 768px) {
    .footer .footer-subdiv div{
       flex-grow: 1;
       min-width: 40%;
       margin-top: 2vh;
    }
  }

@media screen and (max-width: 600px) {
    .footer .footer-subdiv div{
       flex-grow: 1;
       min-width: 60%;
       margin-top: 2vh;
    }
   
  }
 