.welcome{
    width: 100%;
    height: max-content;
    padding: 3vh 0px;
    margin-top: 10vh;
    display: flex;
    background-color: whitesmoke;
    border-radius: 5px;
    align-items: center;
    justify-content: space-around;
}
.welcome .welcome-img{
    width: 40%;
    display: flex;
    justify-content: flex-end;
    
   
}
.welcome .leftt{
    width: 50%;
    margin-left: 5%;
}

.welcome .leftt h2{
    color: rgba(41, 131, 191, 0.9);
    
}
.welcome .leftt button{
    padding: 10px 16px;
    background-color: yellow;
    color: black;
    font-weight: 550;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 3vh;
}
.welcome .leftt button:hover{
    color: white;
    background-color: black;
}
.welcome .welcome-img img{
    height: 50vh;
    width: 80%;
    right: 0px;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .welcome .welcome-img img{
        height: 40vh;
        width: 100%;
    }
}
@media screen and (max-width: 480px) {
    .welcome{
        flex-wrap: wrap-reverse;
        margin-top: 2vh;
    }
    .welcome div{
        min-width: 95%;
        
        
    }
    .welcome .leftt h2{
        font-weight: 550;
        text-align: center;
    }
    .welcome .leftt{
       display: flex;
       flex-direction: column;
       align-items: center;
    }
    
}